<template>
  <div class="programs-list__container">
    <h1 class="text-3xl font-normal mb-4">Programs</h1>
    <RichContent v-if="pageContent" class="main-content" :content="pageContent"/>
    <h2 class="text-xl font-normal mt-8">
      Search Programs
    </h2>
    <BaseAccordion>
      <template #trigger="{ setIsOpen, isOpen }">
        <div class="search-wrapper flex mt-4">
          <SearchInput
            v-model="searchQuery"
            placeholder="Search for programs..."
            :is-loading="isLoading"
            data-test="search-input"
          />
          <BaseButton
            v-if="!hasDepartmentFilter"
            aria-label="Advanced filters toggle"
            title="Advanced filters toggle"
            :aria-expanded="isOpen ? 'true' : 'false'"
            :type="isOpen ? 'primary' : 'secondary'"
            class="ml-4 bg-theme-navbar-background color-theme-navbar-link hover:opacity-80"
            data-test="advanced-filter-btn"
            @click="setIsOpen(!isOpen)"
          >
            <BaseIcon icon="filter" />
          </BaseButton>
        </div>
      </template>
      <template #content>
        <div class="bg-white mb-4 p-4 -mt-8">
          <span
            class="visually-hidden"
            role="status"
          >
            Advanced filters open
          </span>
          <h4 class="font-semibold pt-2">Advanced Search</h4>
          <DepartmentFilter v-model="departmentFilter" />
        </div>
      </template>
    </BaseAccordion>
    <h2 class="text-lg font-normal flex justify-between">
      Results
      <BasePagination
        v-if="total > pageSize"
        :current-page="currentPage"
        :total-pages="totalPages"
        @page-change="updatePage"
      />
    </h2>
    <p role="status">
      <!-- This is commented out because the default behavior is to now list all programs when there is no search query  -->
      <!-- <span v-if="!searchQuery.length && !departmentFilter">
        No search in progress.
      </span> -->
      <span v-if="isLoading">Search in progress</span>
      <span v-else-if="!programs.length">No results</span>
      <span
        v-else
        class="visually-hidden"
      >{{ programs.length }} results found</span>
    </p>
    <div v-if="total" data-test="table-container" class="programs-list__programs">
      <BaseTable
        :rows="programsFormatted"
        @select="goToProgram"
      >
        <template #header>
          <th class="th">Code</th>
          <th class="th">Name</th>
          <th class="th">Type</th>
          <th class="th">Level</th>
          <th class="th">Degree Designation</th>
        </template>
        <template #default="{ row: program }">
          <td>
            <NuxtLink
              v-if="program.code"
              :to="getProgramUrl(program)"
            >
              {{ program.code }}
            </NuxtLink>
          </td>
          <td>
            {{ programDisplayHeading(program) }}
          </td>
          <td>
            {{ program.type }}
          </td>
          <td>
            {{ program.level }}
          </td>
          <td>
            {{ program.degreeDesignation }}
          </td>
        </template>
      </BaseTable>
    </div>
    <div class="max-w-screen-xl mx-auto flex">
      <div class="theme-page-content flex justify-center link-container">
        <a v-if="enablePrintList" data-test-id="print-link" class="link text-md" :href="printPath">
          Download Program List as PDF
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SearchInput from '@/components/SearchInput'
import DepartmentFilter from '@/components/DepartmentFilter'
import RichContent from '@/components/RichContent'
import pageLayout from '@/mixins/pageLayoutMixin'
import usePrograms from '@/composables/usePrograms'
import usePrint from '@/composables/usePrint'
import { programNameWithFallbacks } from '@/utils/entityNames'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { TITLE_INTERPOLATIONS, PRINT_LIST } from '@/constants/featureFlags'
import { generateProgramLabel } from '@/utils/dataLabels'
import useInterpolation from '@/composables/useInterpolation'

export default {
  components: {
    SearchInput,
    DepartmentFilter,
    RichContent,
  },
  mixins: [pageLayout],
  props: {
    pageContent: {
      type: String,
      default: null,
    },
    customFilters: {
      type: Array,
      default: null,
    },
  },
  head() {
    return {
      title: `Programs | ${this.catalogWideMetaTitle}`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Programs | ${this.catalogWideMetaTitle}`,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('settings', ['catalogWideMetaTitle']),
    ...mapState('settings', ['school']),
  },
  setup(_, { root }) {
    const { enableTitleInterpolations, enablePrintList } = useFeatureFlags(
      {
        enableTitleInterpolations: TITLE_INTERPOLATIONS,
        enablePrintList: PRINT_LIST,
      }
    )
    const columns = [
      'code',
      'name',
      'type',
      'level',
      'degreeDesignation',
      'contacts',
      'transcriptDescription',
      'programGroupId',
      ...(enableTitleInterpolations
        ? ['cipCode', 'campus', 'catalogDisplayName']
        : []),
    ]
    const {
      updatePage,
      search,
      goToProgram,
      getProgramUrl,
      fetchData,
      programsFormatted,
      hasDepartmentFilter,
      totalPages,
      departmentFilter,
      programs,
      filters,
      total,
      currentPage,
      isLoading,
      searchQuery,
      pageSize,
    } = usePrograms(root, { columns })

    const { getInterpolatedFieldValue } = useInterpolation()

    const { printPath } = usePrint(root)

    return {
      updatePage,
      search,
      goToProgram,
      getProgramUrl,
      fetchData,
      programsFormatted,
      hasDepartmentFilter,
      totalPages,
      departmentFilter,
      programs,
      filters,
      total,
      currentPage,
      isLoading,
      searchQuery,
      pageSize,
      programNameWithFallbacks,
      enableTitleInterpolations,
      getInterpolatedFieldValue,
      enablePrintList,
      printPath,
    }
  },
  watch: {
    'filters.department': 'search',
    '$route.query': 'fetchData',
  },
  mounted() {
    this.fetchData({
      sortBy: 'name',
    })
  },
  methods: {
    programDisplayHeading(program) {
      return generateProgramLabel(program, this.school, {
        getInterpolatedFieldValue: this.enableTitleInterpolations
          ? this.getInterpolatedFieldValue
          : undefined,
      })
    },
  },
}
</script>

<style lang="css" scoped>
.search-wrapper /deep/ .card {
  display: flex !important;
  margin-bottom: 0;
}

@screen md {
  .programs-list {
    column-count: 2;
  }
}

.link-container {
  width: 240px;
  padding-top: 28px;
  padding-bottom: 28px;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: var(--h2HeaderFontColor, var(--text-gray-900));
  font-family: var(--h2HeaderFontTypeface, inherit);
  font-weight: var(--h2HeaderFontWeight, 700);
}
</style>
