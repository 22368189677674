<template>
  <PageLayoutWrapper
    page="programs/index"
    :props="{ ...$props }"
  >
    <DefaultProgramsView :page-content="pageContent" />
  </PageLayoutWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import PageLayoutWrapper from '@/components/PageLayoutWrapper'
import pageLayout from '@/mixins/pageLayoutMixin'
import DefaultProgramsView from '@/components/DefaultProgramsView'
import { metaTags, metaTitle } from '@/utils/meta'

export default {
  components: {
    DefaultProgramsView,
    PageLayoutWrapper,
  },
  mixins: [pageLayout],
  computed: {
    ...mapGetters('settings', ['layout', 'catalogWideMetaTitle']),
    pageContent() {
      return this.$route.query.preview
        ? this.page?.temporaryContent
        : this.page?.content
    },
  },
  async asyncData({ $axios, $bugsnag, redirect, store }) {
    const url = `/ca/${store.state.settings.school}/catalogPages/${
      store.state.settings.activeCatalog
    }/programs`

    try {
      const page = await $axios.$get(url)
      return {
        page,
      }
    } catch (e) {
      // 404 status (with {} response body) is an expected response here, if the programs pre-built page is not present in the catalog
      // (this page can be removed from catalog)
      // we don't want to pollute bugsnag with those errors
      if (e.response.status !== 404) {
        $bugsnag.notify(e)
      }
      return {
        page: null,
      }
    }
  },
  head() {
    return {
      ...metaTitle(this.page?.metafields, this.catalogWideMetaTitle),
      meta: metaTags(this.page?.metafields),
    }
  },
}
</script>

<style lang="css" scoped>
.search-wrapper /deep/ .card {
  display: flex !important;
  margin-bottom: 0;
}

@screen md {
  .programs-list {
    column-count: 2;
  }
}
</style>
