import _get from 'lodash/get'
import { isEmptyArray } from '@/utils/generalUtils'

const typesWithOptions = ['select', 'radio', 'checkbox']
const shouldHaveOptions = (filter) => typesWithOptions.includes(filter.type)
const hasOptions = (filter) => {
  const optionsList = _get(filter, 'config.options')
  return optionsList && optionsList.length > 0
}
export function fillFiltersOptionsFromQuestions(filters, questions) {
  return filters
    .map((filter) => {
      if (shouldHaveOptions(filter)) {
        const options = (hasOptions(filter)
          ? _get(filter, 'config.options')
          : _get(questions, [filter.questionId, 'config', 'options'], [])
        ).map(
          (option) =>
            typeof option === 'string'
              ? {
                  label: option,
                  value: option,
                }
              : option
        )
        return {
          ...filter,
          config: {
            ...filter.config,
            options,
          },
        }
      }
      return filter
    })
    .filter(
      (filter) =>
        (shouldHaveOptions(filter) && hasOptions(filter)) ||
        !shouldHaveOptions(filter)
    )
}

export function groupResourceFields(fields) {
  return fields.reduce(
    (fields, field) => {
      if (field.startsWith('customFields.')) {
        return {
          ...fields,
          customFields: fields.customFields.concat(field),
        }
      } else if (field.includes('.')) {
        return {
          ...fields,
          nestedFields: fields.nestedFields.concat(field),
        }
      } else {
        return {
          ...fields,
          rootLevelFields: fields.rootLevelFields.concat(field),
        }
      }
    },
    { nestedFields: [], customFields: [], rootLevelFields: [] }
  )
}

export function mergeResourceFieldColumns(columnsA = [], columnsB = []) {
  const { nestedFields, customFields, rootLevelFields } = groupResourceFields(
    Array.from(new Set(columnsA.concat(columnsB)))
  )

  const filteredNestedFields = nestedFields.filter((f) => {
    return !rootLevelFields.find((rf) => f.startsWith(rf))
  })

  return filteredNestedFields.concat(customFields, rootLevelFields)
}

// Convert keys in the route query into filter schema expected by API
export function convertQueryToFilters(queryFilters, questions, filtersPayload = { value: {} }) {
  return Object.keys(queryFilters || {}).reduce((acc, key) => {
    let fieldKey = key
    let type = null

    if (key.includes('-greaterThanEquals')) {
      fieldKey = key.replace('-greaterThanEquals', '')
      type = 'greaterThanEquals'
    } else if (key.includes('-lessThanEquals')) {
      fieldKey = key.replace('-lessThanEquals', '')
      type = 'lessThanEquals'
    }

    const filterRequestDefinition = filtersPayload.value[fieldKey]

    if (
      filterRequestDefinition &&
      ((queryFilters[key] !== undefined &&
        queryFilters[key] !== null &&
        !isEmptyArray(queryFilters[key])) ||
        queryFilters[key] === 0)
    ) {
      acc.push({
        ...filterRequestDefinition,
        value: queryFilters[key],
        ...(type && { type }),
        ...(questions.value[fieldKey]?.custom && {
          customField: true,
        }),
      })
    }
    return acc
  }, [])
}
