import { computed, ref, nextTick } from '@nuxtjs/composition-api'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { PRINT_LIST } from '@/constants/featureFlags'

export default function usePrint(root) {
  const isInPrintMode = computed(() => !!root.$route.query.print)
  const isPrinted = ref(false)

  const printPath = computed(() => {
    return root.$router.resolve({
      ...root.$route,
      query: {
        ...root.$route.query,
        page: undefined,
        print: true,
      },
    }).href
  })

  const { enablePrintList } = useFeatureFlags({
    enablePrintList: PRINT_LIST,
  })

  function printIfInPrintMode() {
    if (enablePrintList && process.client && !isPrinted.value) {
      isPrinted.value = true;
      window.addEventListener("afterprint", afterPrint);
      if (!isInPrintMode.value) return
      nextTick(() => {
        // Wait for render
        // TODO: Find more reliable way to wait for render
        setTimeout(window.print, 1500)
      })
    }
  }

  function afterPrint() {
    const { print, ...query } = root.$route.query
    const { href } = root.$router.resolve({ ...root.$route, query })
    window.removeEventListener("afterprint", afterPrint)
    window.location = href
  }

  return { printIfInPrintMode, isInPrintMode, printPath };
}
