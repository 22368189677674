const LIMIT_SMALL = 10
// Admin app has 25 as mid limit,
// However catalog historically used 20 as normal limit and we are keeping it consistent with that.
const LIMIT_MEDIUM = 20
const LIMIT_LARGE = 50

module.exports = {
  LIMIT_SMALL,
  LIMIT_MEDIUM,
  LIMIT_LARGE,
}
