<template>
  <div class="">
    <label for="department-filter">Filter By Department</label>
    <div class="relative">
      <select
        id="department-filter"
        :selected="value"
        name="Department filter"
        class="block appearance-none w-full border border-gray-400 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-theme-500"
        @change="$emit('input', $event.target.value)"
      >
        <option
          v-for="department of departments"
          :key="department.id"
          :value="department.id"
          :selected="department.id === value"
        >
          {{ department.displayName }}
        </option>
        <option aria-label="None selected" :selected="value === ''" :value="''">-</option>
      </select>
      <div class="pointer-events-none absolute top-0 bottom-0 right-0 mr-2 flex items-center px-2 ">
        <BaseIcon
          icon="chevron-down"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('departments', {
      departments: (state) => state.chosen || state.all,
    }),
  },
}
</script>
