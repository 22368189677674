import { computed, useContext } from '@nuxtjs/composition-api'
import {
  calculateDerivedValue,
  formatDictionaryFromPageTemplate,
} from '@/utils/derivedValueUtils'

export default function useInterpolation() {
  const { store } = useContext()
  const formattedProgramDictionary = computed(
    () =>
      store
        ? formatDictionaryFromPageTemplate(
            store.state.settings.programPageTemplate
          )
        : {}
  )
  const formattedCourseDictionary = computed(
    () =>
      store
        ? formatDictionaryFromPageTemplate(
            store.state.settings.coursePageTemplate
          )
        : {}
  )

  const getInterpolatedFieldValue = function(
    fieldKey,
    values,
    type = 'program'
  ) {
    const formattedDictionary =
      type === 'program'
        ? formattedProgramDictionary.value
        : formattedCourseDictionary.value
    return calculateDerivedValue(formattedDictionary[fieldKey], values)
  }

  return {
    getInterpolatedFieldValue,
  }
}
